<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  AccordionHeader,
  AccordionTrigger,
  type AccordionTriggerProps,
} from 'radix-vue'
import { cn } from '@/utils/utils'

const props = defineProps<
  AccordionTriggerProps & { class?: HTMLAttributes['class'] }
>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <AccordionHeader class="flex">
    <AccordionTrigger v-bind="delegatedProps" :class="cn(
      'flex flex-1  items-center font-bold transition-all  [&[data-state=open]> svg]:rotate-180 text-white text-2xl leading-9 group',
      props.class,
    )
      ">
      <slot />
    </AccordionTrigger>
  </AccordionHeader>
</template>
