<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  AccordionItem,
  type AccordionItemProps,
  useForwardProps,
} from 'radix-vue'
import { cn } from '@/utils/utils'

const props = defineProps<
  AccordionItemProps & { class?: HTMLAttributes['class'] }
>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <AccordionItem v-bind="forwardedProps" :class="cn('bg-secondary-120 rounded-[30px] pl-4 pr-6 py-4', props.class)">
    <slot />
  </AccordionItem>
</template>
